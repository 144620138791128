$green: #4faa84;
$green-light: #528278;
$green-bold: #00604c;
$black: black;
$white: white;

$primary: #12372a !default;
$secondary: #436850 !default;
$info: #adbc9f !default;

:root {
  --bs-primary: #12372a;
  --bs-secondary: #436850;
  --bs-info: #adbc9f;
}



.btn-primary {
  background-color: var(--bs-primary) !important;
  border-color: var(--bs-primary) !important;
  color: #fff !important;
}

.bg-primary {
  background-color: var(--bs-primary) !important;
  color: #fff !important;
}

.bg-secondary {
  background-color: var(--bs-secondary) !important;
  color: #fff !important;
}

.btn-secondary {
  background-color: var(--bs-secondary) !important;
  color: #fff !important;
}

.btn-outline-primary {
  border: 1px solid var(--bs-primary) !important;
  color: var(--bs-primary) !important;
}

.btn-outline-primary:hover {
  background-color: var(--bs-primary) !important;
  color: white !important;
}

.btn-outline-secondary {
  border: 1px solid var(--bs-secondary) !important;
  color: var(--bs-secondary) !important;
}

.btn-outline-secondary:hover {
  background-color: var(--bs-secondary) !important;
  color: white !important;
}

.btn-outline-info {
  border: 1px solid var(--bs-info) !important;
  color: var(--bs-info) !important;
}

.btn-outline-info:hover {
  background-color: var(--bs-info) !important;
  color: white !important;
}

.border-primary {
  border-color: var(--bs-primary) !important;
}

.border-secondary {
  border-color: var(--bs-secondary) !important;
}

.border-info {
  border-color: var(--bs-info) !important;
}