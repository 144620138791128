@import "./assets/styles/variables.scss";
@import 'animate.css';

.btn {
  font-weight: 700 !important;
}

body {
  font-family: "Poppins" !important;
}


.c-pointer {
  cursor: pointer;
}


.flag {
  position: absolute;
  top: 0px;
  right: 0;
  color: #fff;
  padding: 27px 13px;
  border-top-right-radius: 8px;
}

.pointer {
  cursor: pointer;
}

.position-eye {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';